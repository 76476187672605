<template>
  <div>
    <Header v-if="is_detail_homologation" />
    <router-view :class="is_detail_homologation && 'content-wrapper px-3'" />
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  components: {
    Header,
  },
  data() {
    return {
      is_detail_homologation: false,
    };
  },

  methods: {
    checkDetails() {
      const homologation_id = this.$route.params.homologation_id;
      const service_id = this.$route.params.service_id;
      this.is_detail_homologation = !service_id && homologation_id;
    },
  },
  mounted() {
    this.checkDetails();
  },
  watch: {
    $route() {
      this.checkDetails();
    },
  },
};
</script>
<style lang="scss">
/* Used style in edit page service */
.bottom-navigation-service {
  background: $bg;
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0.8em 0.5em;
  @extend .content-wrapper;
  z-index: +10;
  @media (max-width: $break-md) {
    position: relative;
    bottom: 0;
    flex-direction: column-reverse;
    gap: 12px;
    .btn {
      width: 100%;
      margin: 0 !important;
    }
    .action-save {
      display: flex;
      flex-direction: column-reverse;
      gap: 12px;
      width: 100%;
    }
  }
}
</style>
